<template>
  <div>
    <b-card title="资源列表">
      <div class="custom-search">
        <!-- advance search input -->
        <b-row class="mb-2">
          <b-col md="2">
            <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="状态" label-for="input-lg">
              <v-select
                :reduce="val => val.value"
                v-model="queryParams.status"
                :clearable="true"
                :options="statusOptions"
                placeholder="请输入资源状态"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="资源类型" label-for="input-lg">
              <v-select
                :reduce="val => val.value"
                v-model="queryParams.type"
                :clearable="true"
                :options="resourceTypeOptions"
                placeholder="请输入资源类型"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="资源名称" label-for="input-lg">
              <b-form-input id="input-lg" v-model="queryParams.name" placeholder="请输入资源名称" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="人员名称" label-for="input-lg">
              <b-form-input id="input-lg" v-model="queryParams.personName" placeholder="请输入人员名称" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="tRight">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"  @click="pageResource(true)">
              搜索
            </b-button>
          </b-col>
        </b-row>

        <b-button class="mb-2 mr-2" :to="{ name: 'resource-resourceInfo' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          创建
        </b-button>
        <b-button class="mb-2 mr-2" variant="primary" @click="removeResources">
          删除
        </b-button>
        <b-button class="mb-2 mr-2" variant="primary" @click="removePersons">
          移除人员
        </b-button>
      </div>
      <div>
        <vue-good-table ref="resourceTable" :columns="columns" :rows="rows" :rtl="direction"
          :sort-options="{enabled: false,}" :search-options="{enabled: true, externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage:queryParams.pageSize
          }"
          theme="my-theme">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'type'" class="text-nowrap">
              <span>{{ formatResourceType(props.row.type) }}</span>
            </span>

            <span v-else-if="props.column.field === 'status'" class="text-nowrap">
              <span>{{ formatResourceStatus(props.row.status) }}</span>
            </span>

            <span v-else-if="props.column.field === 'action'">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <b-dropdown-item :to="{ name: 'resource-resourceInfo', query: { id: props.row.id , edit: false} }">
                  <span>
                    <feather-icon icon="VoicemailIcon" />
                    <span class="align-middle ml-50">查看</span>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'resource-resourceInfo', query: { id: props.row.id , edit: true} }">
                  <span>
                    <feather-icon icon="VoicemailIcon" />
                    <span class="align-middle ml-50">编辑</span>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="tCenter">
              <b-pagination v-model="queryParams.currentPage" :total-rows="queryParams.total" :per-page="queryParams.pageSize" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @change="currentPageHandleChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton,BDropdown,BDropdownItem,BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      /**
       * 状态option
       */
      statusOptions: [
        {
          label: "离院",
          value: -1,
        }
      ],
      /**
       * 资源类型
       */
      resourceTypeOptions: [
        {
          label: "房间",
          value: -1,
        }
      ],
      /**
       * 框架样式控制
       */
      searchTerm:'',
      dir: false,

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        status:null,
        type:null,
        name:null,
        personName:null,
      },

      /**
       * 当前页数据
       */
      rows: [],
      /**
       * key:value
       */
      columns: [
        {
          label: 'id',
          field: 'id',
        },
        {
          label: '名称',
          field: 'name',
        },
        {
          label: '资源类型',
          field: 'type',
        },
        {
          label: '状态',
          field: 'status',
        },
        {
          label: '定位(关联分组)',
          field: 'tenantPathStr',
        },
        {
          label: '关联人员',
          field: 'personName',
        },
        {
          label: '备注',
          field: 'note',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  created(){
    this.getTenantResourceTypes();
    this.getTenantResourceStatusEnum();
    this.pageResource(true);
  },
  methods: {
    pageResource(resetPage){
      if(resetPage)
        this.currentPage = 1;
      request.post('tob/tenant/tenantResource/pageResourceForWeb', {
        currentPage: this.queryParams.currentPage,
        pageSize: this.queryParams.pageSize,
        status: this.queryParams.status,
        type: this.queryParams.type,
        name: this.queryParams.name,
        personName: this.queryParams.personName,
      })
        .then(res => {
          if (res.data.success) {
            this.rows = res.data.data.data
            this.queryParams.total = res.data.data.count

            console.log('rows', this.rows)
            console.log('total', this.queryParams.total)
          }
        })
    },
    currentPageHandleChange(currentPage){
      this.queryParams.currentPage = currentPage
      this.pageResource()
    },
    /**
     * 删除资源
     */
    removeResources(){
      let resourceList = this.$refs['resourceTable'].selectedRows
      if(resourceList.length <= 0){
        this.$bvToast.toast("请选择要删除的资源",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }

      if (confirm("确认删除资源?")){
        let ids=this.selectRowToIdList(resourceList);
        request.post('tob/tenant/tenantResource/deleteResource', {
          resourceIds:ids
        }).then(res => {
          if (res.data.success) {
            this.$bvToast.toast("删除成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            this.pageResource();
          }else {
            this.$bvToast.toast("删除失败",{
              title: `提示`,
              variant: 'danger',
              solid: true
            })
          }
        })
      }

    },
    /**
     * 移除人员
     */
    removePersons(){
      let resourceList = this.$refs['resourceTable'].selectedRows
      if(resourceList.length <= 0){
        this.$bvToast.toast("请选择要移除的人员",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }

      if (confirm("确认移除?")){
        let ids=this.selectRowToIdList(resourceList);
        request.post('tob/tenant/tenantResource/removePerson', {
          resourceIds:ids
        }).then(res => {
          if (res.data.success) {
            this.$bvToast.toast("移除成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            this.pageResource();
          }else {
            this.$bvToast.toast("移除失败",{
              title: `提示`,
              variant: 'danger',
              solid: true
            })
          }
        })
      }

    },

    selectRowToIdList(resourceList){
      let ids=[];
      for (const resourceElement of resourceList) {
        ids.push(resourceElement.id)
      }
      console.log('resourceList',ids)
      return ids;
    },

    getTenantResourceTypes(){
      request.get('tob/tenant/tenantResource/getTenantResourceTypes', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.resourceTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: value - 0,
              label: key,
            }

            this.resourceTypeOptions.push(enumObject);
          }
        }
      })
    },

    getTenantResourceStatusEnum(){
      request.get('tob/tenant/tenantResource/getTenantResourceStatusEnum', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.statusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: value - 0,
              label: key,
            }

            this.statusOptions.push(enumObject);
          }
        }
      })
    },

    formatResourceType(value){
      for (const typeElement of this.resourceTypeOptions) {
        if (typeElement.value === value){
          return typeElement.label;
        }
      }
      return value;
    },

    formatResourceStatus(value){
      for (const statusElement of this.statusOptions) {
        if (statusElement.value === value){
          return statusElement.label;
        }
      }
      return value;
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
